<template>
  <div class="pay-page">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span v-if="type === 'partBidding'">参与竞价</span>
      <span v-if="type === 'pushBidding'">发布竞价</span>
      <span v-if="type === 'buy'">详情</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>支付</span>
    </div>
    <div class="box">
      <div class="title">
        <img src="@/assets/images/recreation-icon.png" alt="" />
        <p>支付方式</p>
      </div>
      <div class="content">
        <p v-if="type === 'buy'" class="tip">需支付商品金额为</p>
        <p v-if="type === 'partBidding' || type === 'pushBidding'" class="tip">需支付保证金为</p>
        <div class="amount" style="display: flex; align-items: center; justify-content: center">
          <text>¥</text>
          <div v-if="type === 'buy'">
            <text
              style="
                font-size: 28px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ff211b;
              "
              v-if="params.money"
            >
              {{ params.money }}
            </text>
            <text
              v-else
              style="
                font-size: 28px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ff211b;
              "
            >
              {{ params.amount / 100 }}
            </text>
          </div>
          <text v-if="type === 'partBidding'">{{ biddingDeposit }}</text>
          <text v-if="type === 'pushBidding'">{{ params.deposit }}</text>
        </div>
        <p
          v-if="type === 'partBidding'"
          style="
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top: 12px;
          "
        >
          起拍竞价：¥{{ params.amount / 100 }}
        </p>
        <p
          v-if="type === 'pushBidding'"
          style="
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top: 12px;
          "
        >
          起拍竞价：¥{{ params.amount }}
        </p>
        <!-- <div class="timing">
          <text>支付剩余时间:</text>
          <text>15:00</text>
        </div> -->
        <a-radio-group v-model:value="value1" @change="radioChange">
          <a-radio-button value="alipay">
            <img src="@/assets/images/pay-alipay.png" alt="" />
            <text>支付宝</text>
          </a-radio-button>
          <a-radio-button value="balance">
            <img src="@/assets/images/pay-balance.png" alt="" />
            <text>余额({{ balance / 100 }})</text>
          </a-radio-button>
        </a-radio-group>
      </div>
      <div v-html="alipayHtml"></div>
      <div class="submit" @click="submitClick">确认支付</div>
    </div>
  </div>
  <el-dialog v-model="dialogVisible" title="" width="30%" :show-close="false">
    <div class="popup-box">
      <img src="@/assets/images/success-icon.png" alt="" />
      <p>恭喜您，支付成功</p>
      <div @click="close">确定</div>
    </div>
  </el-dialog>
</template>
<script setup>
import { ref, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { balancePay } from '@/api/goods'
import { userInfo } from '@/api/user'
import { placeOrder, orderSign } from '@/api/order'
import { ElMessage } from 'element-plus'
const alipayHtml = ref('')
const route = useRoute()
const router = useRouter()
const value1 = ref('')
const balance = ref('')
const dialogVisible = ref(false)
const type = ref('')
const params = JSON.parse(route.query.params)
const biddingDeposit = ref('')
function radioChange() {
  console.log(value1)
}
function getUserInfo() {
  userInfo().then((res) => {
    if (res.code == 200) {
      balance.value = res.data.balance
      biddingDeposit.value = Number(res.data.config.bidding_deposit) / 100
    }
  })
}
nextTick(() => {
  getUserInfo()
})

function submitClick() {
  if (!value1.value) {
    ElMessage.warning('请选择支付方式!')
    return
  }
  if (value1.value === 'balance') {
    if (type.value === 'buy') {
      // params.type = 0
      params.pay_type = 0
    } else if (type.value === 'partBidding') {
      // params.type = 4
      params.amount = biddingDeposit.value * 100
      params.pay_type = 0
    } else if (type.value === 'pushBidding') {
      // params.type = 2
      params.amount = params.deposit * 100
      params.pay_type = 0
      delete params.amount
    }
    if (params.type == 6) {
      delete params.money
    }
    placeOrder(params).then((res) => {
      if (res.code == 200) {
        balancePay({ order_no: res.data.order_no }).then((result) => {
          if (result.code == 200) {
            if (
              type.value === 'buy' ||
              type.value === 'partBidding' ||
              type.value === 'pushBidding'
            ) {
              dialogVisible.value = true
            }
          } else {
            ElMessage.error(result.msg)
          }
        })
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    if (type.value === 'buy') {
      // params.type = 0
      params.pay_type = 1
    } else if (type.value === 'partBidding') {
      params.type = 4
      params.amount = biddingDeposit.value * 100
      params.pay_type = 1
    } else if (type.value === 'pushBidding') {
      params.type = 2
      params.amount = params.deposit * 100
      params.pay_type = 1
      delete params.amount
    }
    if (params.type == 6) {
      delete params.money
    }
    placeOrder(params).then((res) => {
      if (res.code == 200) {
        orderSign({ order_no: res.data.order_no, type: 1 }).then((result) => {
          alipayHtml.value = result
          nextTick(() => {
            document.forms['alipaysubmit'].target = '_blank'
            document.forms['alipaysubmit'].submit()
            alipayHtml.value = ''
          })
        })
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}
function close() {
  if (type.value === 'buy') {
    router.push({ path: '/user', query: { current: 1 } })
  } else if (type.value === 'partBidding') {
    router.push({ path: '/user', query: { current: 2 } })
  } else if (type.value === 'pushBidding') {
    router.push({ path: '/user', query: { current: 4 } })
  }
  dialogVisible.value = false
}
onMounted(() => {
  type.value = route.query.type
})
</script>
<style lang="less" scoped>
.pay-page {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  .breadcrumb {
    margin: 14px 0;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    img {
      margin: 0 3px;
    }
    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      cursor: auto;
    }
  }
  .box {
    .title {
      width: 1200px;
      height: 45px;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      border-radius: 12px 12px 0px 0px;
      display: flex;
      align-items: center;
      padding-left: 18px;
      img {
        width: 20px;
        height: 15px;
      }
      p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 5px;
      }
    }
    .content {
      width: 1200px;
      height: 367px;
      background: #ffffff;
      text-align: center;
      border-radius: 0 0 12px 12px;
      padding-top: 50px;
      .tip {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b3b3b3;
      }
      .amount {
        margin-top: 12px;
        text:nth-child(1) {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff211b;
        }
        text:nth-child(2) {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff211b;
        }
      }
      .timing {
        width: 200px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
        border-radius: 18px;
        margin: 0 auto;
        margin-top: 24px;
        text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #06a4f9;
        }
      }
      .ant-radio-group {
        margin-top: 26px;
        .ant-radio-button-wrapper {
          width: 200px;
          height: 60px;
          line-height: 60px;
          border-radius: 8px;
          border: 2px solid #e6e6e6;
          text {
            margin-left: 6px;
          }
        }
        .ant-radio-button-wrapper:nth-child(2) {
          margin-left: 48px;
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
          content: none;
        }
        .ant-radio-button-wrapper-checked {
          background: url('~@/assets/images/pay-status.png') no-repeat;
        }
      }
    }
    .submit {
      width: 302px;
      height: 51px;
      text-align: center;
      line-height: 51px;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 12px;
      margin: 0 auto;
      margin-top: 36px;
      cursor: pointer;
    }
  }
}
.popup-box {
  text-align: center;
  p {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
    margin-top: 20px;
  }
  div {
    width: 204px;
    height: 50px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 26px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin: 0 auto;
    margin-top: 28px;
    cursor: pointer;
  }
}
</style>
